<template>
    <div>
        <b-row>
            <!-- Second Answer Headline -->
            <b-col md="12">
                <b-form-group label="Second Answer Headline" label-for="secondAnswerHeadline">
                    <b-form-input type="text" name="secondAnswerHeadline" v-model="prop_siteSettingArray.secondAnswerHeadline" />
                </b-form-group>
            </b-col>

            <!-- Second Answer Button Text -->
            <b-col md="12">
                <b-form-group label="Second Answer Button Text" label-for="secondAnswerButtonText">
                    <b-form-input type="text" name="secondAnswerButtonText" v-model="prop_siteSettingArray.secondAnswerButtonText" />
                </b-form-group>
            </b-col>

            <!-- Font family -->
            <b-col md="12">
                <b-form-group label="Font family" label-for="secondAnswerFontFamily">
                    <Select2 v-model="prop_siteSettingArray.secondAnswerFontFamily" :options="secondAnswerFontFamily" name="questionFontFamily" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BInputGroup } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BInputGroup
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.secondAnswerHeadline)) {
                this.prop_siteSettingArray.secondAnswerHeadline = 'Welcome back! Check out our new sale.';
            }
            if (_.isEmpty(this.prop_siteSettingArray.secondAnswerButtonText)) {
                this.prop_siteSettingArray.secondAnswerButtonText = 'Shop now';
            }
            if (_.isEmpty(this.prop_siteSettingArray.secondAnswerFontFamily)) {
                this.prop_siteSettingArray.secondAnswerFontFamily = 'Open Sans';
            }
        },
        setup() {
            const { secondAnswerFontFamily } = captureOptions();
            return {
                secondAnswerFontFamily
            }
        },
    }
</script>